.booking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background: var(--color-bg-variant);
    color: white;
    margin: 0;
    width: 100%;
    padding: 3rem 0;
}

.booking-container > h3 {
    text-align: center;
    color: var(--color-bg);
}

.booking-h2 {
    color: var(--color-bg);
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .booking-container.container {
        width: 100%;
    }
  }
  
  /*======== MEDIA QUERIES (SMALL DEVICES) ======== */
  @media screen and (max-width: 600px) {
    .booking-container.container {
        width: 100%;
    }
  }