@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  /* outline: 1px solid tomato; */
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  /* overflow: hidden; */
}

:root {
  --color-bg: #fff;
  --color-bg-variant: #004e7c;
  --color-text: #333;
  --color-primary: #f58220;
  --color-primary-brown: #9f282b;
  --color-white: #fff;
  --color-light: rgba(51, 51, 51, 0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  background: var(--color-bg);
  color: var(--color-text);
  line-height: 1.7;
}

/* h1,h2 {
  margin: 1rem 0;
} */

h4 {
  color: var(--color-light);
  font-weight: 400;
}


section {
  margin: 2rem 0;
}

.container {
  margin: 0 auto;
  width: var(--container-width-lg);
  margin-bottom: 3rem;
}

/* .container>h2,h3 {
color: var(--color-bg-variant);
} */

.text-image {
  border-radius: 1rem;
  /* margin-top: 2rem; */
  margin-right: 1rem;

}

.hero-img {
  width: 100%;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}