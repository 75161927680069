header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    width: 100%;
}

#navbar {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navLink :hover {
    color: var(--color-white);
}

.btn {
    padding: 1.2rem 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    border: 2px solid transparent;
}

.a-btn {
    padding: 0.5rem 1rem;
}

.btn-primary {
    background: var(--color-primary);
    border-radius: 0.8rem;
    font-weight: 600;
    color: var(--color-white);
}

.btn-primary:hover {
    background: transparent;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
}

.navbar-item {
    color: black;
}

.is-active {
    color: tomato;
    text-decoration: underline;
}

#mobile {
    display: none;
    font-size: 2.5rem;
    color: var(--color-primary);
    cursor: pointer;
}

.contact-nav {
    color: var(--text-color);
}



@media screen and (max-width: 1366px) {
    .btn-primary {
        white-space: nowrap;
        font-size: 0.8rem;

    }

    #navbar {
        font-size: 0.8rem;
    }
    .logo {
        width: 70%;
    }
    nav>ul{
        margin-bottom: 0;
    }
}

/*================== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    #mobile {
        display: block;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 20rem;
        font-weight: 500;
        padding: 2.5rem 1.5rem;
        top: 6.25rem;
        font-size: 1.2rem;
        right: 0rem;
        background: aliceblue;
        gap: 5rem;
        z-index: 2;
        height: 100%;
        transition: 0.4s ease-in-out;
    }

    #navbar.active {
        right: -25rem;
    }

    .book-now {
        font-size: 0.9rem;
    }

    .logo {
        width: 90%;
    }
}

/*================= MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .book-now {
        display: none;
    }

    header {
        padding: 1rem;
    }

    .logo {
        width: 70%;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 20rem;
        font-weight: 500;
        padding: 2.5rem 1.5rem;
        top: 5.5rem;
        font-size: 1.2rem;
        right: 0rem;
        background: aliceblue;
        gap: 5rem;
        z-index: 2;
        height: 100%;
        transition: 0.4s ease-in-out;
    }
}