.hero {
    margin-bottom: 5rem;
} 
  .swiper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
  }
  
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--swiper-theme-color);
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

  .slider-text-div{
    position: absolute;
    z-index: 900;
    left: 4rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    width: 20rem;
    color: var(--color-white);
  }

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
  .slider-text-div{
    font-size: 1rem;
  }
}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
  .slider-text-div{
    display: none;
  }
}