.myo-container {
    display: flex;
    flex-direction: column;

}
/* .mayo-intro {
    display: flex;
} */

.text-image-mayo {
    float: left;
    width: 50%;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .text-image-mayo {
        width: 60%;
        margin-top: 0;
    }


  }
  
  /*======== MEDIA QUERIES (SMALL DEVICES) ======== */
  @media screen and (max-width: 600px) {

  }

