.container-physiotherapy {
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.hero-physio {
    width: 100%;
}

.container-physiotherapy section,
h3 {
    text-align: left;
    width: 100%;
}

.physiotherapy-list {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.list-icon {
    color: var(--color-primary-brown);
    font-size: 1.8rem;
}

.text-image-physio {
    width: 40%;
    border-radius: 1rem;
    float: left;
}

.list-data {
    width: 80%;
}

.physio-list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* flex-wrap: wrap; */
}

.check {
    font-size: 2rem;
    color: var(--color-primary);
}

.list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 100%;
    /* align-items: flex-start; */
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .container-physiotherapy.container {
        width: var(--container-width-md);
    }


}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .container-physiotherapy {
        width: var(--container-width-sm);
    }

    .list-container {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1rem;
    }

    .text-image-physio {
        width: 60%;
    }
}