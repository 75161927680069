.container.container-testimonials {
    width: 60%;
    display: flex;
    padding-bottom: 2rem;
}
.client-avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.swiper-wrapper {
    display: flex;
    align-items: center;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--color-primary);
}
.testimonial {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background: var(--color-bg);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    gap: 1rem;
}
.testimonial h4 {
    color: var(--color-text);
}
.client-name {
    text-align: center;
}
.client-review {
    color: var(--color-text);
    font-weight: 300;
    display: block;
    /* width: 80%; */
    margin: 0.8rem auto 0;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .container.container-testimonials {
        width: 60%;
    }
}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .container.container-testimonials {
        width: var(--container-width-sm);
        padding-bottom: 0;
        margin-top: -3rem;
    }
    .client-review {
        width: var(--container-width-sm);
    }
}