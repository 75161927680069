
.services-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    /* flex-direction: column; */
    row-gap: 6rem;
}

.data-div {
    display: flex;
    flex-direction: column;
    width: 20rem;
    
    
}
.services-image {
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.read-more-link {
    display: flex;
    align-items: center;
}
/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .about-container {
        flex-direction: column;
    }
    .about-image {
        width: 100%;
    }
    .services-container {
        
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    /* flex-direction: column; */
    row-gap: 6rem;
    }

}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .services-container {
        
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        /* flex-direction: column; */
        row-gap: 6rem;
        }
}
