h2,
h4 {
    text-align: center;
}
a {
    color: var(--color-bg-variant);
}
.address {
    text-align: left;
}

.contact_container {
    /* width: 40%; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 3rem;
}

.contact_options {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 1.2rem;

}

.contact_option-item {
    display: flex;
    width: 100%;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: left;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

/* .contact_option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    } */

.contact_option-icon {
    font-size: 1.5rem;
}

.contact_option-item a {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.socials {
    display: flex;
    font-size: 2rem;
    gap: 2.5rem;
    /* justify-content: center; */
    align-items: center;
}

.socials a {
    color: var(--color-bg);
    padding: 1rem;
    background: var(--color-primary);
    border-radius: 5rem;
    display: flex;
    transition: var(--transition);
}

.socials a:hover {
    background: transparent;
    color: var(--color-primary);
}

.contact_option-item > a > h4 {
    color: var(--color-text);
}

/* ===============FORM================== */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid var(--color-primary);
    resize: none;
    color: var(--color-bg-variant);
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
.contact_container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {

    .socials {
        justify-content: center;

    }

    .contact_options {
        gap: 1.2rem;
    
    }

    .contact_option-item a {
        gap: 1rem;
    }
    
    .contact_option-item {
        width: 100%;
        font-size: 0.9rem;
    }
}