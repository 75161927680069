/* *{
    outline: 1px solid tomato;
} */
.ortho-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.li-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.bullet {
    font-size: 2rem;
    min-width: 10%;
    color: var(--color-primary);
}

.ortho-container-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-image-orthotics {
    width: 30%;
    /* float: right; */
}
.text-image-2 {
    width: 30%;
}
.ortho-container-2 {
    display: flex;
    /* align-items: center; */
}

.ortho-container-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ortho-container-3>h3{
    text-align: center;
    margin-bottom: 2rem;
}

.image-div {
    display: flex;
    gap: 2rem;
}

.image-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-img {
    width: 100%;
}

.image-row>p {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d9e8ff;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0.25rem;
    height: 100%;
    font-weight: 500;

}
.text-div-1 > h3 {
    margin-bottom: 1rem;
}

.text-div-1 > p {
    margin-bottom: 2rem;
}
.image-item {
    width: 100%;
}

.text-div-2 > h3,ul {
    margin-bottom: 1rem;
}

.text-div-2 > ul {
    margin-bottom: 2rem;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .text-image-orthotics {
        aspect-ratio: 1 / 1;
        width: 20rem;
        height: 25rem;
    }
    .image-div {
        gap: 1rem;
    }
    .text-image-2 {
        width: 40%;
    }

  }
  
  /*======== MEDIA QUERIES (SMALL DEVICES) ======== */
  @media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    .ortho-container-1 {
        flex-direction: column;
        align-items: center;
    }

    .ortho-container-2 {
        flex-direction: column-reverse;
        align-items: center;
    }

    .text-image-orthotics {
        width: 100%;
        margin-bottom: 2rem;
    }
    .text-image-2 {
        width: 100%;
        margin-top: 2rem;
    }

    .image-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
  }