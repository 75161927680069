.working-container {
    background: var(--color-primary);
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    color: var(--color-bg-variant);
    gap: 2rem;
}

.working-container>h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: white;
}

.hours {
    display: flex;
    justify-content: space-between;
    margin: 0%;
    width: 75%;
}

.hours-text-div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.hours-text {
    color: var(--color-white);
}

.hours-icon {
    font-size: 4rem;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .working-container.container {
        width: 100%;
        margin: 0;
    }

    .hours-icon {
        font-size: 2rem;
    }

    .hours {
        width: 90%;
        gap: 2rem;
    }

    .hours-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        gap: 1rem;
    }

    .hours-text-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .working-container.container {
        width: 100%;
        margin: 0;
    }

    .hours {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0%;
        width: 90%;
    }

}