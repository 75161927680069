
.container-massage {
    display: flex;
    flex-direction: column;
}

.text-image-massage {
    width: 30%;
    float: left;
}
.massage-icon-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.massage-icon-div > h4 {
    text-align: left;
}
.massage-bottom-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: 2rem;
}

.icons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.img-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.img-massage-last {
    width: 40%;
    border-radius: 1rem;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .massage-bottom-section{
        display: flex;
        flex-direction: column-reverse;
        /* justify-content: space-between; */
        align-items: center;
        text-align: center;
        gap: 2rem;
    }
    .icons-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        grid-column-gap: 5rem;
        grid-row-gap: 2rem;
    }
    .massage-icon-div > h4 {
        text-align: center;
    }

    .massage-icon-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text-image-massage {
        width: 50%;
        float: left;
    }
  }

  
  /*======== MEDIA QUERIES (SMALL DEVICES) ======== */
  @media screen and (max-width: 600px) {
   
  }
