.pelvic-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pelvic-div-bottom {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.text-image-pelvic {
    margin-top: 2rem;
    width: 50%;
    float: left;
}

.pelvic-image-2 {
    width: 40%;
    border-radius: 1rem;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .container-physiotherapy.container {
        width: var(--container-width-md);
    }

    .pelvic-div-bottom {
        flex-direction: column;
    }

    .pelvic-image-2 {
        width: 100%;
    }


}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .container-physiotherapy {
        width: var(--container-width-sm);
    }


}