footer {
    background: var(--color-bg-variant);
    color: var(--color-white);
    text-align: center;
    padding: 0.75rem 0;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    footer>small {
        font-size: xx-small;
    }

}