
.about-container {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 5rem;
}

.about-image {
    width: 90%;
    border-radius: 1rem;
}

.sub-text {
    margin-top: 2rem;
}

.welcome-text-div>h1{
    color: var(--color-bg-variant);
}
.welcome-text-div>h3{
    color: var(--color-primary-brown);
    margin-bottom: 1rem;
}

/*======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .about-container {
        flex-direction: column;
    }
    .about-image {
        width: 100%;
    }

}

/*======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .about-image {
        width: 90%;
    }
}